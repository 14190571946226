import styles from './styles.css'
import formatters from 'lib/formatters'
import { Table } from 'components/Table'
import { Cell, Row } from 'components/Table'

function renderRow({ event, index }) {
  return (
    <li key={`${event.createdAt}-${index}`}>
      <p>{formatters.fileTimestamp(event.createdAt)}</p>
      <p>{event.createdByUser}</p>
      <p>{event.description}</p>
    </li>
  )
}

const LogInfo = ({ events, headers }) => {
  return (
    <div className={styles.logInfo}>
      <p className={styles.logInfoTitle}>Log Information</p>

      {headers == null ? (
        <ul className={styles.logList}>
          {events.map((event, index) => renderRow({ event, index }))}
        </ul>
      ) : (
        <Table headers={headers} tableSize="infinite" tableType="read">
          {events.map((cell, index) => {
            return (
              <Row key={`${cell.createdAt}-${index}-a`}>
                <Cell
                  key={`${cell.createdAt}-${index}-1`}
                  value={formatters.fileTimestamp(cell.createdAt)}
                  className={`${styles.logStyle} ${styles.logStyleCell}`}
                />
                <Cell
                  key={`${cell.createdAt}-${index}-2`}
                  value={cell.createdByUser}
                  className={styles.logStyleCell}
                />
                <Cell
                  key={`${cell.createdAt}-${index}-3`}
                  value={cell.description}
                  className={styles.logStyleCell}
                />
              </Row>
            )
          })}
        </Table>
      )}
    </div>
  )
}

export default LogInfo
