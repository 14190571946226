import CheckboxSelect from '../../../../../components/CheckBoxSelect'
import styles from './pendingRequest.css'
import RadioOption from '../../../../../components/RadioOption'
import { pendingRequestOptions, pendingRequestRadioOptions } from './constant'
import SectionHeader from '../Header/SectionHeader'

export const PendingRequest = ({ clientSelect, setClientSelect, trustSelect, setTrustSelect }) => {
  const handleCheckboxChange = (updatedOptions) => {
    setClientSelect(updatedOptions)
  }
  const handleTrustSelect = (e) => {
    setTrustSelect(e.target.value)
  }
  const clearRadio = () => {
    setTrustSelect(null)
  }

  return (
    <div className={styles.pendingRequestLayout}>
      <div className={styles.pendingRequestContainer}>
        <SectionHeader title="Pending Request Actions" />
        <div className={styles.sections}>
          <section>
            <p>Client Responsibilty</p>
            <CheckboxSelect
              options={pendingRequestOptions}
              selectedOptions={clientSelect}
              onChange={handleCheckboxChange}
            />
          </section>
          <section>
            <p>
              Internal Responsibilty{' '}
              {trustSelect && (
                <button className={styles.radioClear} onClick={clearRadio}>
                  Clear
                </button>
              )}
            </p>
            {pendingRequestRadioOptions.map((option) => {
              return (
                <RadioOption
                  key={option.id}
                  name={option.label}
                  value={option.id}
                  label={option.label}
                  checked={option.id === trustSelect}
                  onChange={handleTrustSelect}
                />
              )
            })}
          </section>
        </div>
      </div>
    </div>
  )
}
