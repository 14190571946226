// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__buttons___zQC7B {
  display: flex;
  justify-content: space-between;
}

.styles__buttonsLeft___prkYZ {
  justify-content: left;
}

.styles__buttonsRight___mUEYG {
  justify-content: right;
}

.styles__button___uRRyD {
}

.styles__deleteBtn___rCos1 {
}

.styles__cancelBtn___qyJxG {
  margin-left: 14px;
}

.styles__approve___yMzAi {
}

.styles__reject___Nj67a {
}

.styles__errors___QNFDT {
  margin-bottom: 20px;
}

.styles__errors___QNFDT h4,
  .styles__errors___QNFDT h5 {
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  }
`, "",{"version":3,"sources":["webpack://./universal/components/FormButtons/styles.css"],"names":[],"mappings":"AAIA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,iBAAiB;AACnB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,mBAAmB;AAMrB;;AAJE;;IAEE,mDAAY;EACd","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlueLight, gtBlue, gtGreen, gtRed from colors;\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.buttonsLeft {\n  justify-content: left;\n}\n\n.buttonsRight {\n  justify-content: right;\n}\n\n.button {\n  composes: button from helpers;\n}\n\n.deleteBtn {\n  composes: buttonDanger from helpers;\n}\n\n.cancelBtn {\n  composes: button;\n  margin-left: 14px;\n}\n\n.approve {\n  composes: button from helpers;\n}\n\n.reject {\n  composes: buttonDanger from helpers;\n}\n\n.errors {\n  composes: sectionSmall from helpers;\n  margin-bottom: 20px;\n\n  h4,\n  h5 {\n    color: gtRed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlueLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]}`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"buttons": `styles__buttons___zQC7B`,
	"buttonsLeft": `styles__buttonsLeft___prkYZ`,
	"buttonsRight": `styles__buttonsRight___mUEYG`,
	"button": `styles__button___uRRyD ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"deleteBtn": `styles__deleteBtn___rCos1 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["buttonDanger"]}`,
	"cancelBtn": `styles__cancelBtn___qyJxG styles__button___uRRyD ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"approve": `styles__approve___yMzAi ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"reject": `styles__reject___Nj67a ${___CSS_LOADER_ICSS_IMPORT_1___.locals["buttonDanger"]}`,
	"errors": `styles__errors___QNFDT ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionSmall"]}`
};
export default ___CSS_LOADER_EXPORT___;
