import LinkButton from 'components/LinkButton/LinkButton'
import { Table } from 'components/Table'
import { httpGet } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { sortByKey } from './sort'
import { getNewStatus, headers, summaryBoxNavList, tabTitleList } from './constant'
import RenderDistributionRow from './row'
import styles from './styles'
import TableFilter from './TableFilter'
import { colorTheme } from '../colors/color'
import { withRouter } from 'lib/hooks/withRouter'
import { navigateWithoutRedirect } from './configuration'
import { decodedData } from '../../../../../lib/encrypt'

const DistributionsQueue = ({ location }) => {
  const dispatch = useDispatch()
  const { query, navigate } = location
  const tableId = decodedData(query.get('tid'))
  const subTableId = decodedData(query.get('stid'))
  const _filterKey = query.get('fkid')
    ? JSON.parse(decodedData(query.get('fkid')))?.filterKey
    : null
  const _sort = query.get('sort') ? JSON.parse(decodedData(query.get('sort'))) : null
  const [counts, setCounts] = useState({})
  const [distributions, setDistributions] = useState([])
  const [fiteredDistributions, setFilteredDistributions] = useState([])
  const [nextStatus, setNextStatus] = useState()
  const [currentStatus, setCurrentStatus] = useState(tableId)
  const [filterKey, setFilterKey] = useState(_filterKey)
  const [sort, setSort] = useState(_sort != null ? _sort : { key: null, type: null, column: null })
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [color, setColor] = useState('cyan')
  const [search, setSearch] = useState('')
  const dateFormat = (d) => moment(d).format('dddd MMMM D, YYYY')

  const resetFilter = () => {
    setSort({ key: null, type: null, column: null })
    setFilterKey(null)
  }
  const handleTabSelect = (index) => {
    setSelectedTabIndex(Number(index))
    const newStatus = getNewStatus(currentStatus, Number(index))
    setNextStatus(newStatus)
    navigateWithoutRedirect('stid', index)
    // resetFilter()
  }

  const tabTitle = (title, countKey) => {
    let fullTitle = title + ' ('

    if (counts.hasOwnProperty(countKey)) {
      fullTitle += counts[countKey]
    } else {
      fullTitle += '0'
    }

    return fullTitle + ')'
  }

  const isNavActive = (isActive) =>
    isActive
      ? `${styles.navItemActive} ${styles.navItem} ${colorTheme[color].textDark} ${
          colorTheme[color].borderMid ?? colorTheme[color].borderLight
        }`
      : styles.navItem
  const handleCurrentStatus = (key, click) => {
    let status = key
    if (key === 'main') {
      status = 'written'
    } else if (key === 'recurring') {
      status = 'recurring_written'
    }
    setNextStatus(status)
    setCurrentStatus(key)
    setSelectedTabIndex(0)
    const foundColor = summaryBoxNavList().find((li) => li.countKey === key)
    foundColor?.theme && setColor(foundColor.theme)

    navigateWithoutRedirect('tid', key)
    if (click) {
      resetFilter()
    }
  }
  const handleFilter = (key, id) => {
    setSort((prev) => {
      let options = {
        key,
        id: id,
        type: prev.type === 'asc' || !prev.type ? 'desc' : 'asc',
      }
      if (prev.key != key) {
        options.type = 'asc'
      }
      navigateWithoutRedirect('sort', JSON.stringify(options))
      return options
    })
  }

  const navigateToDrf = () => {
    navigate(`/distributions/${search}`)
  }

  const filterDistributions = () => {
    dispatch(setLoad())
    let dis = distributions
    if (filterKey != null) {
      dis = distributions?.filter((distribution) =>
        filterKey?.includes(distribution.account.number[0]),
      )
    }
    if (sort != null) {
      dis = sortByKey(dis, sort.key, sort.type)
    }
    setFilteredDistributions(dis)
    dispatch(setUnload())
  }

  useEffect(() => {
    filterDistributions()
  }, [filterKey, sort, distributions])

  useEffect(() => {
    if (nextStatus != null) {
      const query = queryString.stringify({ next_status: nextStatus })
      const url = '/admin/distributions?' + query

      dispatch(setLoad())

      httpGet(url)
        .then(({ distributions, counts }) => {
          setDistributions(distributions)
          if (filterKey) {
            const dis = distributions?.filter((distribution) =>
              filterKey?.includes(distribution.account.number[0]),
            )
            setFilteredDistributions(dis)
          } else {
            setFilteredDistributions(sortByKey(distributions, 'dateReceived', 'asc'))
          }
          setCounts(counts)
          dispatch(setUnload())
        })
        .catch((err) => {
          // reset table
          setDistributions([])
          setFilteredDistributions([])
          // show error
          dispatch(setBanner(`Error fetching distributions - ${err}`, 'danger'))
          // close loading
          dispatch(setUnload())
        })
    }
  }, [nextStatus, currentStatus])

  useEffect(() => {
    handleCurrentStatus(tableId == null ? 'main' : tableId)
    if (tableId != null) {
      handleTabSelect(subTableId == null ? 0 : subTableId)
    }
  }, [tableId, subTableId])

  return (
    <div>
      <div className={styles.distributionHeaderWrapper}>
        <div className={styles.distributionHeader}>
          <div className={styles.distributionHeaderLeft}>
            <h3 className={styles.drfHeader}>Disbursement Requests</h3>
            <div>
              <p className={styles.headerDay}>Today</p>
              <h3 className={styles.headerDate}>{dateFormat(new Date())}</h3>
            </div>
          </div>
          <div className={styles.distributionHeaderRight}>
            <LinkButton className={styles.newRequestButton} to="/distributions/received">
              + Receive Request
            </LinkButton>
            <form className={styles.drfSearch} onSubmit={navigateToDrf}>
              <input
                className={styles.textInput}
                type="number"
                name="searchById"
                placeholder="Enter DRF ID"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                required
              />
              <button type="submit" className={styles.button} disabled={search == null}>
                Go
              </button>
            </form>
          </div>
        </div>
      </div>
      <section className={styles.contentContainer}>
        <div className={styles.fullScreen}>
          <Tabs
            selectedIndex={selectedTabIndex}
            onSelect={handleTabSelect}
            className="tabsContainer"
          >
            <ul className={styles.distributionHeaderNav}>
              {summaryBoxNavList().map((item, index) => (
                <li
                  key={`${item.countKey}-${item.title}-${index}`}
                  className={isNavActive(item.countKey === currentStatus)}
                  onClick={() => {
                    handleCurrentStatus(item.countKey, true)
                    navigateWithoutRedirect('stid', 0)
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            <TableFilter setFilterKey={setFilterKey} filterKey={filterKey} color={color} />
            <TabList
              className={[
                styles.tabList,
                'react-tabs',
                colorTheme[color].bgMid ?? colorTheme[color].bgLight,
              ]}
            >
              {tabTitleList(currentStatus).map((item, index) => (
                <Tab
                  key={`${item.title}-${item.countKey}-${index}`}
                  className={[styles.buttonLocal, 'react-tabs__tab', 'react-tabs__capitalize']}
                >
                  {tabTitle(item.title, item.countKey)}
                </Tab>
              ))}
            </TabList>
            {tabTitleList(currentStatus).map((item) => (
              <TabPanel key={item.title}>
                <div className={styles.tableContainer}>
                  <Table
                    headers={headers({
                      currentStatus,
                      sort,
                      nextStatus,
                      handleFilter,
                      className: styles.tableHeader,
                    })}
                    tableSize="infinite"
                    tableType="read"
                  >
                    {fiteredDistributions?.map((dist, index) => (
                      <RenderDistributionRow
                        key={`$${index}-table`}
                        dist={dist}
                        tableStatus={currentStatus}
                        nextStatus={nextStatus}
                      />
                    ))}
                  </Table>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </section>
    </div>
  )
}

export default withRouter(DistributionsQueue)
