// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles'
import classNames from 'classnames'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type CellProps = {
  account?: string,
  bold?: boolean,
  button?: string,
  className?: string,
  italic?: boolean,
  mono?: boolean,
  centered?: boolean,
  status?: Object,
  type?: string,
  value: string,
  handleFilter?: any,
  sort?: { key: string | null, type: 'asc' | 'desc' },
  className?: string,
}

export const Cell = (props: CellProps) => {
  const {
    className = '',
    value,
    status,
    bold,
    account,
    italic,
    mono,
    centered,
    button,
    onClick,
  } = props
  let cellStyle = value ? styles.tdCell : styles.noValue
  if (value) {
    if (bold) {
      cellStyle = styles.boldValue
    }
    if (mono) {
      cellStyle = styles.monoValue
    }
    if (centered) {
      cellStyle = styles.centeredValue
    }
    if (italic) {
      cellStyle = styles.italicValue
    }
  }

  let innerClass
  if (status) {
    innerClass = styles[status]
  } else if (button) {
    innerClass = styles[button]
  } else if (account) {
    innerClass = styles[account]
  }
  if (onClick) {
    return (
      <div
        className={`${cellStyle} ${className} ${styles.cellBtn}`}
        role="button"
        onClick={onClick}
      >
        <span className={innerClass}>{value || '—'}</span>
      </div>
    )
  }

  return (
    <div className={`${cellStyle} ${className}`}>
      <span className={innerClass}>{value || '—'}</span>
    </div>
  )
}

export const HeaderCell = ({
  value,
  type,
  filter,
  handleFilter,
  className,
  sort,
  id,
}: CellProps) => {
  let body = (
    <div>
      {value}
      {!className && <div className={styles.fixed}>{typeof value !== 'object' && value}</div>}
    </div>
  )

  if (type === 'mono') {
    body = (
      <div className={styles.floatRight}>
        {value}
        <div className={styles.fixed}>{value}</div>
      </div>
    )
  } else if (type === 'link') {
    body = <div className={styles.headerLink}>{value}</div>
  }
  const isColumnSorted = filter?.includes(sort?.id)

  return (
    <div
      className={className ? `${styles.thCell} ${className}` : styles.thCell}
      style={filter && { cursor: 'pointer' }}
      onClick={() => handleFilter && handleFilter(filter, id)}
      role="button"
    >
      <span className={styles.thCellSpan}>
        {body}{' '}
        {filter ? (
          <div className={styles.arrowIcons}>
            <KeyboardArrowUpIcon
              sx={{
                color: isColumnSorted && sort?.type === 'asc' ? '#31333f' : '#66666696',
                fontSize: '20px',
                marginBottom: '-12px',
              }}
            />
            <KeyboardArrowDownIcon
              sx={{
                color: isColumnSorted && sort?.type === 'desc' ? '#31333f' : '#66666696',
                fontSize: '20px',
              }}
            />
          </div>
        ) : null}
      </span>
    </div>
  )
}

type RowProps = {
  children?: Element,
  highlight?: boolean,
  link?: string,
  onClick?: Function,
  selected?: boolean,
  className?: string,
}

export const Row = (rowProps: RowProps) => {
  const { children, highlight, link, onClick, selected, className } = rowProps
  let selectedStyle = selected ? styles.selected : styles.tdRow
  let highlightStyle = highlight ? styles.highlight : ''

  if (link) {
    return (
      <Link to={link} className={`${selectedStyle} ${highlightStyle} ${className ?? ''}`}>
        {children}
      </Link>
    )
  }

  return (
    <div
      onClick={onClick}
      className={`${selectedStyle} ${highlightStyle} ${className ?? ''}`}
      style={onClick && { cursor: 'pointer' }}
    >
      {children}
    </div>
  )
}

type TableProps = {
  children?: Element,
  headers?: Array<string>,
  isOpen?: boolean,
  title?: string,
  tableSize?: string,
  tableType?: string,
  noResults?: string,
}

export class Table extends React.Component<TableProps> {
  constructor(props: TableProps) {
    super(props)

    let tableSize = styles.tableMedium
    switch (props.tableSize) {
      case 'large':
        tableSize = styles.tableLarge
        break
      case 'medium':
        tableSize = styles.tableMedium
        break
      case 'small':
        tableSize = styles.tableSmall
        break
      case 'infinite':
        tableSize = undefined
        break
    }

    let tableType = props.tableType === 'read' ? styles.tableRead : styles.tableClick

    this.state = {
      isOpen: !!props.isOpen,
      tableSize,
      tableType,
    }
  }
  renderHeaders = () => {
    const { headers } = this.props
    return (
      <div className={styles.thRow}>
        {headers.map((header, i) => (
          <HeaderCell
            key={i}
            id={header?.id}
            value={header.value}
            type={header.type}
            filter={header.filter}
            handleFilter={header.handleFilter}
            sort={header.sort}
            className={header.className}
          />
        ))}
      </div>
    )
  }

  render() {
    const { tableSize } = this.state
    const { children, title } = this.props
    let tableClasses = classNames(tableSize)
    let noResults = null
    if (!children || !children.length) {
      noResults = (
        <div className={styles.noResults}>
          {this.props.noResults == null ? 'No Results' : this.props.noResults}
        </div>
      )
    }

    let tableTitle = <div></div>

    if (title) {
      tableTitle = <h5 className={styles.tableTitle}>{title}</h5>
    }

    let main = (
      <div className={tableClasses}>
        <div className={styles.table}>
          {this.renderHeaders()}
          {children}
        </div>
      </div>
    )

    return (
      <section className={styles.tableSection}>
        {tableTitle}
        {main}
        {noResults}
      </section>
    )
  }
}
