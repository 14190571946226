// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Primary */

/* elements */

/* Account colors */

/* Text */

/* Borders */

/* old */
`, "",{"version":3,"sources":["webpack://./universal/styles/colors.css"],"names":[],"mappings":"AAAA,YAAY;;AAkCZ,aAAa;;AAIb,mBAAmB;;AAGnB,SAAS;;AAOT,YAAY;;AAIZ,QAAQ","sourcesContent":["/* Primary */\n@value gtBlue: #376EA1;\n@value gtBlueDark: #3D6183;\n@value gtBlueLight: #E3EBF5;\n@value gtBlueDull: #edeff3;\n@value gtGray: #EDECED;\n@value gtGrayDark: #666666;\n@value gtGrayLight: #F7F7F7;\n@value gtGrayMid: #E8E8E8;\n@value gtGreen: #5FABA1;\n@value gtGreenDark: #5FABA0;\n@value gtGreenLight: #F9FCFC;\n@value gtRed: #A13739;\n@value gtRedDark: #8E3132;\n@value gtRedLight: #FFF0F2;\n@value gtYellow: #E7C76D;\n@value gtYellowLight: #FAF4E5;\n@value gtYellowDark: #B68E1E;\n\n@value gtPurpleDark: #6D597A;\n@value gtPurpleMid: #dad6dc;\n@value gtPurpleLight: #e7e5e8;\n@value gtCyanDark: #1E7085;\n@value gtCyanMid: #ccdadf;\n@value gtCyanLight: #e1e8ea;\n@value gtAmberDark: #B88C2F;\n@value gtAmberMid: #e8e0cf;\n@value gtAmberLight: #efebe2;\n@value gtOrangeDark: #BF6139;\n@value gtOrangeLight: #ebd7d0;\n@value gtLimeDark: #5e7442;\n@value gtLimeMid: #d7dbd1;\n@value gtLimeLight: #e6e8e4;\n\n/* elements */\n@value gtBackground: #F5F5F5;\n@value gtCardHeaderBG: #FAFAFA;\n\n/* Account colors */\n@value gtGPT: #3D5972;\n\n/* Text */\n@value textLight: #9B9B9B;\n@value text: #666666;\n@value textDark: #31333F;\n\n@value textGray: #5f5f5f;\n\n/* Borders */\n@value borderColor: #E1E1E1;\n@value borderColorLight: #F2F2F2;\n\n/* old */\n@value gtBlueMedium: #5B86AD;\n@value gtBlueBlack: #3D5972;\n@value gtGreenDark: #438177;\n@value gtGrayMediumLight: #EDEFED;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gtBlue": `#376EA1`,
	"gtBlueDark": `#3D6183`,
	"gtBlueLight": `#E3EBF5`,
	"gtBlueDull": `#edeff3`,
	"gtGray": `#EDECED`,
	"gtGrayDark": `#666666`,
	"gtGrayLight": `#F7F7F7`,
	"gtGrayMid": `#E8E8E8`,
	"gtGreen": `#5FABA1`,
	"gtGreenDark": `#438177`,
	"gtGreenLight": `#F9FCFC`,
	"gtRed": `#A13739`,
	"gtRedDark": `#8E3132`,
	"gtRedLight": `#FFF0F2`,
	"gtYellow": `#E7C76D`,
	"gtYellowLight": `#FAF4E5`,
	"gtYellowDark": `#B68E1E`,
	"gtPurpleDark": `#6D597A`,
	"gtPurpleMid": `#dad6dc`,
	"gtPurpleLight": `#e7e5e8`,
	"gtCyanDark": `#1E7085`,
	"gtCyanMid": `#ccdadf`,
	"gtCyanLight": `#e1e8ea`,
	"gtAmberDark": `#B88C2F`,
	"gtAmberMid": `#e8e0cf`,
	"gtAmberLight": `#efebe2`,
	"gtOrangeDark": `#BF6139`,
	"gtOrangeLight": `#ebd7d0`,
	"gtLimeDark": `#5e7442`,
	"gtLimeMid": `#d7dbd1`,
	"gtLimeLight": `#e6e8e4`,
	"gtBackground": `#F5F5F5`,
	"gtCardHeaderBG": `#FAFAFA`,
	"gtGPT": `#3D5972`,
	"textLight": `#9B9B9B`,
	"text": `#666666`,
	"textDark": `#31333F`,
	"textGray": `#5f5f5f`,
	"borderColor": `#E1E1E1`,
	"borderColorLight": `#F2F2F2`,
	"gtBlueMedium": `#5B86AD`,
	"gtBlueBlack": `#3D5972`,
	"gtGrayMediumLight": `#EDEFED`
};
export default ___CSS_LOADER_EXPORT___;
