import React from 'react'
import IconBox from './IconBox'
/* eslint-disable max-len */

export function IconRecurring() {
  return (
    <IconBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.0"
        height={28}
        width={28}
        viewBox="0 0 60 60"
      >
        <defs>
          <clipPath id="30bce7fefb">
            <path
              d="M 0 28 L 59.863281 28 L 59.863281 59.863281 L 0 59.863281 Z M 0 28 "
              clip-rule="nonzero"
            ></path>
          </clipPath>
          <clipPath id="d0dc92625a">
            <path
              d="M 13.160156 13.160156 L 46.683594 13.160156 L 46.683594 46.683594 L 13.160156 46.683594 Z M 13.160156 13.160156 "
              clip-rule="nonzero"
            ></path>
          </clipPath>
        </defs>
        <g clip-path="url(#30bce7fefb)">
          <path
            fill="#6D597A"
            d="M 58.007812 28.011719 C 56.910156 28.011719 56.019531 28.902344 56.019531 30 C 56.015625 44.347656 44.34375 56.019531 30 56.019531 C 30 56.019531 29.996094 56.019531 29.996094 56.019531 C 20.863281 56.019531 12.539062 51.316406 7.820312 43.617188 L 12.089844 43.617188 C 13.1875 43.617188 14.078125 42.726562 14.078125 41.628906 C 14.078125 40.53125 13.1875 39.636719 12.089844 39.636719 L 2.515625 39.636719 C 1.988281 39.636719 1.480469 39.847656 1.109375 40.222656 C 0.734375 40.59375 0.523438 41.101562 0.523438 41.628906 L 0.527344 51.203125 C 0.527344 52.300781 1.417969 53.191406 2.515625 53.191406 C 3.613281 53.191406 4.503906 52.300781 4.503906 51.203125 L 4.503906 45.824219 C 6.925781 49.71875 10.21875 53.023438 14.132812 55.464844 C 16.464844 56.921875 18.980469 58.046875 21.609375 58.8125 C 24.324219 59.597656 27.144531 60 29.996094 60 C 29.996094 60 30 60 30 60 C 34.046875 60 37.976562 59.207031 41.675781 57.640625 C 43.453125 56.890625 45.167969 55.957031 46.773438 54.875 C 48.359375 53.800781 49.855469 52.570312 51.210938 51.214844 C 52.566406 49.855469 53.800781 48.363281 54.875 46.773438 C 55.957031 45.171875 56.886719 43.457031 57.640625 41.679688 C 59.203125 37.980469 60 34.050781 60 30 C 60 28.902344 59.109375 28.011719 58.007812 28.011719 Z M 58.007812 28.011719 "
            fill-opacity="1"
            fill-rule="nonzero"
          ></path>
        </g>
        <path
          fill="#6D597A"
          d="M 1.992188 31.992188 C 3.089844 31.988281 3.980469 31.097656 3.980469 30 C 3.980469 23.050781 6.683594 16.515625 11.597656 11.601562 C 16.515625 6.6875 23.046875 3.980469 30 3.980469 C 38.800781 3.980469 46.851562 8.347656 51.648438 15.554688 L 46.304688 15.554688 C 45.207031 15.554688 44.316406 16.445312 44.316406 17.546875 C 44.316406 18.644531 45.207031 19.535156 46.304688 19.535156 L 56.351562 19.535156 C 57.449219 19.535156 58.339844 18.644531 58.339844 17.542969 L 58.339844 7.5 C 58.339844 6.402344 57.449219 5.511719 56.351562 5.511719 C 55.25 5.511719 54.359375 6.402344 54.359375 7.5 L 54.359375 12.492188 C 52.082031 9.324219 49.199219 6.617188 45.863281 4.535156 C 43.535156 3.082031 41.019531 1.957031 38.386719 1.191406 C 35.675781 0.402344 32.851562 0.00390625 30 0.00390625 C 25.949219 0.00390625 22.019531 0.796875 18.320312 2.359375 C 16.542969 3.113281 14.828125 4.042969 13.222656 5.125 C 11.636719 6.199219 10.140625 7.429688 8.785156 8.789062 C 7.429688 10.144531 6.195312 11.636719 5.125 13.226562 C 4.039062 14.832031 3.109375 16.546875 2.359375 18.324219 C 0.792969 22.023438 0 25.953125 0 30 C 0 31.101562 0.890625 31.992188 1.992188 31.992188 Z M 1.992188 31.992188 "
          fill-opacity="1"
          fill-rule="nonzero"
        ></path>
        <g clip-path="url(#d0dc92625a)">
          <path
            fill="#6D597A"
            d="M 30 13.160156 C 20.714844 13.160156 13.160156 20.714844 13.160156 30 C 13.160156 39.28125 20.714844 46.835938 30 46.835938 C 39.28125 46.835938 46.835938 39.28125 46.835938 30 C 46.835938 20.714844 39.28125 13.160156 30 13.160156 Z M 31.683594 38.5 L 31.683594 39.679688 C 31.683594 40.609375 30.925781 41.363281 30 41.363281 C 29.070312 41.363281 28.3125 40.609375 28.3125 39.679688 L 28.3125 38.476562 C 25.511719 38.011719 24.015625 36.527344 23.828125 36.328125 C 23.035156 35.476562 23.082031 34.144531 23.933594 33.351562 C 24.78125 32.554688 26.113281 32.605469 26.90625 33.453125 C 26.914062 33.460938 27.925781 34.417969 30 34.417969 C 30.757812 34.417969 32.523438 34.304688 32.523438 33.257812 C 32.523438 32.707031 32.523438 32.457031 29.707031 32.058594 C 27.453125 31.746094 23.261719 31.15625 23.261719 26.691406 C 23.261719 24.914062 23.972656 22.066406 28.3125 21.480469 L 28.3125 20.316406 C 28.3125 19.386719 29.070312 18.632812 30 18.632812 C 30.925781 18.632812 31.683594 19.386719 31.683594 20.316406 L 31.683594 21.554688 C 32.78125 21.78125 33.640625 22.132812 33.773438 22.1875 C 34.84375 22.644531 35.335938 23.882812 34.878906 24.953125 C 34.421875 26.019531 33.183594 26.511719 32.121094 26.058594 C 31.796875 25.921875 30.796875 25.578125 30 25.578125 C 27.472656 25.578125 27.472656 26.144531 27.472656 26.691406 C 27.472656 27.246094 27.472656 27.496094 30.292969 27.894531 C 32.542969 28.210938 36.734375 28.800781 36.734375 33.261719 C 36.734375 35.433594 35.371094 37.921875 31.683594 38.5 Z M 31.683594 38.5 "
            fill-opacity="1"
            fill-rule="nonzero"
          ></path>
        </g>
      </svg>
    </IconBox>
  )
}
