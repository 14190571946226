import AccountSearch from 'components/AccountSearch'
import SimpleFileFolder from 'components/SimpleFileFolder'
import DateInput from '../../../../../../components/DateInput'
import SectionHeader from '../../Header/SectionHeader'
import formatters from 'lib/formatters'
import styles from '../styles.css'
import { MONTHS } from '../constant'

export default function WriteFormOne({
  onUploadClick,
  files,
  onDeleteClick,
  form,
  updateForm,
  formDisabled,
  handleAccountSelect,
  recurringDrf,
  recurringFormDisabled,
  handleRecurringUpdate,
  currentDrfRecurring,
  nextStatus,
  lockedForEdit,
}) {
  const disableFields = formDisabled && ['admin_wrapped_up'].includes(nextStatus)

  const getEndDate = (type, month) => {
    if (
      form?.recurringDistribution?.period == 'monthly' &&
      form.recurringDistribution?.dayRepeats != null
    ) {
      return formatters.getDateOn(3, type, form.recurringDistribution?.dayRepeats)
    }
    return formatters.getDateOn(month, type)
  }

  return (
    <div className={styles.sectionOne}>
      {/* recurring section  */}
      {(recurringDrf || currentDrfRecurring) && (
        <section className={styles.cardMarginBot}>
          {(formDisabled || recurringFormDisabled) && <div className={styles.disabledMask}></div>}
          <SectionHeader title={'Recurring'} />
          <section className={styles.sectionNoMargin}>
            <section className={styles.subSection}>
              <div className={styles.radioButtonGroup}>
                <label className={styles.label} htmlFor="period">
                  Recurring Cycle
                </label>
                <div className={`${styles.buttons} ${styles.checkboxVertical}`}>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="weekly"
                      checked={form?.recurringDistribution?.period === 'weekly'}
                      onChange={handleRecurringUpdate}
                    />
                    <label htmlFor="weekly">Weekly</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="monthly"
                      checked={form?.recurringDistribution?.period === 'monthly'}
                      onChange={handleRecurringUpdate}
                    />
                    <label htmlFor="monthly">Monthly</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="quaterly"
                      checked={form?.recurringDistribution?.period === 'quaterly'}
                      onChange={handleRecurringUpdate}
                    />
                    <label htmlFor="quaterly">Quaterly</label>
                  </div>
                </div>
              </div>
            </section>
            {form?.recurringDistribution?.period == 'monthly' && (
              <section className={styles.subSection}>
                <div className={styles.formItemHalf}>
                  <label className={styles.label} htmlFor="dayRepeats">
                    Initiate Request on This Day of the Month
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="dayRepeats"
                      value={form?.recurringDistribution?.dayRepeats || ''}
                      onChange={handleRecurringUpdate}
                      required
                    >
                      <option value="">Choose Day</option>
                      {[...new Array(31)].map((item, index) => (
                        <option key={`${index}-day-repeats-options`} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            )}
            {form?.recurringDistribution?.period === 'quaterly' && (
              <section className={styles.subSection}>
                <div className={styles.formItemMono}>
                  <label className={styles.label} htmlFor="startMonth">
                    Start Month
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="startMonth"
                      value={form?.recurringDistribution?.startMonth || ''}
                      onChange={handleRecurringUpdate}
                      required
                    >
                      <option value="">Choose Day</option>
                      {MONTHS.map((item, index) => (
                        <option key={`${index}-start-month-options`} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            )}
            <section className={styles.subSection}>
              <div className={styles.formItemMono}>
                <label className={styles.label} htmlFor="endDate">
                  End Date / Next Review Date
                </label>
                <DateInput
                  name="endDate"
                  value={form.recurringDistribution?.endDate}
                  onChange={handleRecurringUpdate}
                  disabled={formDisabled}
                  min={getEndDate('forth', 3)}
                  max={formatters.getDateOn(12, 'forth')}
                  required
                />
              </div>
            </section>
          </section>
        </section>
      )}
      <section className={styles.cardMarginBot}>
        {!lockedForEdit && formDisabled && !['admin_wrapped_up'].includes(nextStatus) && (
          <div className={styles.disabledMask}></div>
        )}
        <SectionHeader title={recurringDrf ? 'Uploads' : 'Receive Info'} />
        <section className={styles.sectionNoMargin}>
          <section className={styles.subSection}>
            <div className={styles.fileUpload}>
              <label className={styles.label}>Request Documents</label>
              <div className={styles.fileUploadComponent}>
                <SimpleFileFolder
                  onUploadClick={onUploadClick}
                  files={files}
                  onDeleteClick={onDeleteClick}
                />
              </div>
            </div>
          </section>
          {!recurringDrf && (
            <>
              <section className={styles.subSection}>
                <div className={styles.formItemMono}>
                  <label className={styles.label} htmlFor="dateReceived">
                    Date Received
                  </label>
                  <DateInput
                    name="dateReceived"
                    value={form.dateReceived}
                    onChange={updateForm}
                    disabled={formDisabled || disableFields}
                    required
                  />
                </div>
              </section>
              <section className={styles.subSection}>
                <div className={styles.formItemMono}>
                  <label className={styles.label} htmlFor="account">
                    Account Number
                  </label>
                  <div className={styles.searchComponent}>
                    <AccountSearch
                      onAccountSelect={handleAccountSelect}
                      valueInsideInput={
                        form.account && `${form.account.number} - ${form.account.beneficiary}`
                      }
                      disabled={formDisabled}
                    />
                  </div>
                </div>
              </section>

              <section className={styles.subSection}>
                <div className={styles.formItemHalf}>
                  <label className={styles.label} htmlFor="requestType">
                    Request Type
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="requestType"
                      value={form.requestType || ''}
                      onChange={updateForm}
                      disabled={formDisabled || disableFields}
                      required
                    >
                      <option value="">Choose Type</option>
                      <option value="automobile">Automobile</option>
                      <option value="entertainment">Entertainment</option>
                      <option value="food">Food</option>
                      <option value="housing">Housing</option>
                      <option value="insurance">Insurance</option>
                      <option value="misc">Misc</option>
                      <option value="services">Services</option>
                      <option value="utilities">Utilities</option>
                      <option value="credit_card">Credit Card</option>
                      <option value="reimburse">Reimbursement</option>
                    </select>
                  </div>
                </div>
              </section>
              <section className={styles.subSection}>
                <div className={styles.radioButtonGroup}>
                  <label className={styles.label} htmlFor="expeditedPaymentNeeded">
                    Expedited Payment Needed
                  </label>
                  <div className={styles.buttons}>
                    <input
                      type="radio"
                      name="expeditedPaymentNeeded"
                      value="yes"
                      checked={form.expeditedPaymentNeeded === 'yes'}
                      onChange={updateForm}
                      disabled={formDisabled || disableFields}
                    />
                    <label htmlFor="yes">Yes</label>
                    <input
                      type="radio"
                      name="expeditedPaymentNeeded"
                      value="no"
                      checked={form.expeditedPaymentNeeded === 'no'}
                      disabled={disableFields}
                      onChange={updateForm}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </section>
              {form.expeditedPaymentNeeded === 'yes' && (
                <section className={styles.subSection}>
                  <div className={styles.formItemMono}>
                    <label className={styles.label} htmlFor="expeditedDateNeeded">
                      Expedited Payment Date Needed
                    </label>
                    <DateInput
                      name="expeditedDateNeeded"
                      value={form.expeditedDateNeeded}
                      onChange={updateForm}
                      disabled={formDisabled || disableFields}
                      required
                    />
                  </div>
                </section>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  )
}
