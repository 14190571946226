import AccountSearch from 'components/AccountSearch'
import SimpleFileFolder from 'components/SimpleFileFolder'
import formatters from 'lib/formatters'
import { httpDelete, httpPost } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import DateInput from '../../../../../components/DateInput'
import SectionHeader from '../Header/SectionHeader'
import DrfHeader from '../HeaderStrip/DrfHeader'
import styles from './styles'

function ReceivedForm() {
  const [form, setForm] = useState({
    account: '',
    dateReceived: '',
    expeditedPaymentNeeded: '',
    expeditedDateNeeded: '',
    requestType: '',
  })
  const [temporaryId] = useState(uuidv4())
  const [files, setFiles] = useState([])

  const dispatch = useDispatch()

  const updateForm = useCallback((e) => {
    e.persist()
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }, [])

  const handleAccountSelect = (account) => {
    setForm((prev) => ({ ...prev, account: account }))
  }

  useEffect(() => {
    if (form.expeditedPaymentNeeded !== 'yes') {
      setForm((prev) => ({ ...prev, expeditedDateNeeded: '' }))
    }
  }, [form.expeditedPaymentNeeded])

  useEffect(() => {
    // Set default values
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy = today.getFullYear()
    const todayString = yyyy + '-' + mm + '-' + dd
    setForm((prev) => ({
      ...prev,
      dateReceived: todayString,
      expeditedPaymentNeeded: 'no',
    }))
  }, [])

  const buildUploadParams = async (fileName, file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

    const data = await toBase64(file)
    const params = {
      content_type: file.type,
      name: fileName,
      data: data.split(',')[1],
    }

    return params
  }

  const onUploadClick = (fileName, file) => {
    buildUploadParams(fileName, file).then((params) => {
      const url = `/admin/distributions/${temporaryId}/files`

      dispatch(setLoad())
      httpPost(url, params)
        .then((data) => {
          dispatch(setBanner('File uploaded'))
          setFiles(data.files)
          dispatch(setUnload())
        })
        .catch((err) => {
          dispatch(setBanner(`Error saving - ${err}`, 'danger'))
          dispatch(setUnload())
        })
    })
  }

  const onDeleteClick = (fileId) => {
    const url = `/admin/distributions/files/${fileId}`

    httpDelete(url)
      .then((res) => {
        setFiles(files.filter((file) => file.id !== fileId))
        dispatch(setBanner('File deleted'))
      })
      .catch((err) => {
        dispatch(setBanner(`Error deleting - ${err}`, 'danger'))
      })
  }

  const canSubmit = () => {
    return form.account && form.dateReceived && form.requestType && files.length > 0
  }

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      const params = {
        temporary_id: temporaryId,
        account_number: form.account.number,
        date_received: form.dateReceived,
        request_type: form.requestType,
        expedited_date_needed: form.expeditedDateNeeded,
      }

      dispatch(setLoad())
      return httpPost('/admin/distributions', params).then((res) => {
        dispatch(setUnload())
        window.location.reload()
      })
    },
    [form],
  )

  // date should be in MM/DD/YY
  const header = {
    title: 'Receive',
    subheader: 'Disbursement Request',
    stepper: {
      steps: [
        {
          label: 'Receive',
          date: formatters.date(form.dateReceived),
        },
        { label: 'Write' },
        { label: 'Review' },
        { label: 'Approve & Pay' },
      ],
      activeStep: 0,
    },
  }

  return (
    <>
      <DrfHeader stepper={header} color={'cyan'} />
      <div className={styles.contentContainer}>
        <div className={styles.oneThird}>
          <form className={styles.form}>
            <section className={styles.cardMarginBot}>
              <SectionHeader title="Receive Info" />
              <section className={styles.sectionNoMargin}>
                <section className={styles.subSection}>
                  <div className={styles.fileUpload}>
                    <label className={styles.label}>Request Documents</label>
                    <div className={styles.fileUploadComponent}>
                      <SimpleFileFolder
                        onUploadClick={onUploadClick}
                        files={files}
                        onDeleteClick={onDeleteClick}
                      />
                    </div>
                  </div>
                </section>
                <section className={styles.subSection}>
                  <div className={styles.formItemMono}>
                    <label className={styles.label} htmlFor="dateReceived">
                      Date Received
                    </label>
                    <DateInput
                      name="dateReceived"
                      value={form.dateReceived}
                      onChange={updateForm}
                      required
                    />
                  </div>
                </section>
                <section className={styles.subSection}>
                  <div className={styles.formItemMono}>
                    <label className={styles.label} htmlFor="accountNumber">
                      Account Number
                    </label>
                    <div className={styles.searchComponent}>
                      <AccountSearch
                        onAccountSelect={handleAccountSelect}
                        valueInsideInput={
                          form.account && `${form.account.number} - ${form.account.beneficiary}`
                        }
                      />
                    </div>
                  </div>
                </section>
                <section className={styles.subSection}>
                  <div>
                    <label className={styles.label} htmlFor="requestType">
                      Request Type
                    </label>
                    <div>
                      <select
                        className={styles.select}
                        name="requestType"
                        value={form.requestType}
                        onChange={updateForm}
                        required
                      >
                        <option value="">Choose Type</option>
                        <option value="automobile">Automobile</option>
                        <option value="entertainment">Entertainment</option>
                        <option value="food">Food</option>
                        <option value="housing">Housing</option>
                        <option value="insurance">Insurance</option>
                        <option value="misc">Misc</option>
                        <option value="services">Services</option>
                        <option value="utilities">Utilities</option>
                        <option value="credit_card">Credit Card</option>
                        <option value="reimburse">Reimbursement</option>
                      </select>
                    </div>
                  </div>
                </section>
                <section className={styles.subSection}>
                  <div className={styles.radioButtonGroup}>
                    <label className={styles.label} htmlFor="expeditedPaymentNeeded">
                      Expedited Payment Needed
                    </label>
                    <div className={styles.buttons}>
                      <input
                        type="radio"
                        name="expeditedPaymentNeeded"
                        value="yes"
                        checked={form.expeditedPaymentNeeded === 'yes'}
                        onChange={updateForm}
                      />
                      <label htmlFor="yes">Yes</label>
                      <input
                        type="radio"
                        name="expeditedPaymentNeeded"
                        value="no"
                        checked={form.expeditedPaymentNeeded === 'no'}
                        onChange={updateForm}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </section>
                {form.expeditedPaymentNeeded === 'yes' && (
                  <section className={styles.subSection}>
                    <div className={styles.formItemMono}>
                      <label className={styles.label} htmlFor="expeditedDateNeeded">
                        Expedited Date Needed
                      </label>
                      <input
                        className={styles.input}
                        type="date"
                        name="expeditedDateNeeded"
                        value={form.expeditedDateNeeded}
                        onChange={updateForm}
                        required
                      />
                    </div>
                  </section>
                )}
              </section>
            </section>
            <section className={styles.submitButtons}>
              <button
                disabled={!canSubmit()}
                className={styles.button}
                type="submit"
                onClick={handleSubmit}
              >
                Save and Create Another
              </button>
            </section>
          </form>
        </div>
      </div>
    </>
  )
}

export default ReceivedForm
