import formatters from 'lib/formatters'
import { httpGet } from 'lib/http'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IconRedirect } from '../../../../../components/Icons/IconRedirect'
import styles from './Header.css'
import AccordionStylingExpansion from 'components/Accordion/Accordion'
import RequestInfo from '../RequestInfo'

type Props = {
  number: string,
  beneficiary: string,
}

const renderClosingHeader = (status) => {
  let component = <div></div>

  if (status == 'closed') {
    component = <h5 className={styles.closedStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'closing') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  } else if (status == 'suspended') {
    component = <h5 className={styles.closingStatus}>{status.toUpperCase()}</h5>
  }

  return component
}

const renderDeceasedHeader = (dod) => {
  if (dod) {
    return <h5 className={styles.deceasedFlag}>DECEASED</h5>
  } else {
    return <div></div>
  }
}

const renderCheckingBalance = (checkingBalance) => {
  if (checkingBalance) {
    return formatters.money(checkingBalance)
  } else {
    return '$-.--'
  }
}

const Header = (props: Props) => {
  const { number, beneficiary, otherDistributions, touchNav } = props

  const [balance, setBalance] = useState('')
  const [checkingBalance, setCheckingBalance] = useState('')
  const [dod, setDod] = useState('')
  const [lastUpdate, setLastUpdate] = useState('')
  const [openDate, setOpenDate] = useState('')
  const [status, setStatus] = useState('')
  const [trustName, setTrustName] = useState('')
  const [productDescription, setProductDescription] = useState('')
  const [subaccountBalance, setSubaccountBalance] = useState('')
  const [notes, setNotes] = useState('')

  // const standardHeight = balance == checkingBalance ? '' : styles.standardHeight

  useEffect(() => {
    httpGet(`/admin/accounts/${number}?summary=true`)
      .then((data) => {
        setBalance(data.balance)
        setCheckingBalance(data.checkingBalance)
        setDod(data.dod)
        setLastUpdate(data.lastUpdate)
        setOpenDate(data.openDate)
        setStatus(data.status)
        setTrustName(data.trustName)
        setProductDescription(data.productDescription)
        setSubaccountBalance(data.subaccountBalance)
        setNotes(data.notes)
      })
      .catch((err) => null)
  }, [number])

  return (
    <div className={`${styles.titleBox} ${touchNav && styles.titleHeaderHeight}`}>
      <div className={styles.titleBoxRow}>
        <div className={`${styles.nameWrapper}`}>
          <span className={styles.titleName}>
            <div className={styles.trusts}>
              <div className={styles.trustsHeader}>
                <Link
                  to={`/accounts/${number}`}
                  className={styles.redirectIcon}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  <h5 className={styles.accountNumber}>{number}</h5>
                </Link>
                <Link
                  to={`/accounts/${number}`}
                  className={styles.redirectIcon}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  <IconRedirect h={16} w={16} />
                </Link>
              </div>
              {renderClosingHeader(status)}
            </div>
          </span>
          <div>
            <div className={styles.accountNumberLine}>
              <h1>
                <Link
                  to={`/accounts/${number}`}
                  className={styles.beneName}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  {beneficiary}
                </Link>
              </h1>
              {dod && renderDeceasedHeader(dod)}
            </div>
            <div className={styles.trustName}>{trustName}</div>
            <div className={styles.productDescription}>{productDescription}</div>
          </div>
        </div>

        <div className={`${styles.balanceWrapper}`}>
          <div>
            <p className={styles.boxLabel}>Main Account Balance</p>
            <h2>{renderCheckingBalance(checkingBalance)}</h2>
          </div>
          {subaccountBalance != 0 && (
            <div className={styles.subAccount}>
              <p className={styles.boxLabel}>Subaccounts Balance</p>
              <h2>{renderCheckingBalance(subaccountBalance)}</h2>
            </div>
          )}
        </div>

        <div className={`${styles.accountStatusWrapper}`}>
          <div>
            <p className={styles.accountBoxLabel}>Account Information</p>
            <p className={styles.accountStatusCaption}>
              Last updated {formatters.date(lastUpdate)}
            </p>
          </div>
          <div>
            <p className={`${styles.accountBoxLabel} ${styles.accountBoxStatus}`}>
              {formatters.legibleAccountStatus(status)}
            </p>
            <p className={styles.accountStatusCaption}>Open {formatters.date(openDate)}</p>
          </div>
        </div>
        <div className={`${styles.accountStatusWrapper}`}>
          {/* Todo in future: add <AccountExtrasRef> */}
        </div>
        <div className={`${styles.accountStatusWrapper}`} style={{ padding: '0 6px' }}>
          <AccordionStylingExpansion
            items={[
              {
                title: 'OPEN REQUESTS',
                isEmpty: otherDistributions.open.length === 0,
                details: (
                  <RequestInfo
                    data={otherDistributions.open}
                    total={otherDistributions.openTotal}
                    totalType={'Open'}
                  />
                ),
              },
              {
                title: 'PENDING REQUESTS',
                isEmpty: otherDistributions.pending.length === 0,
                details: (
                  <RequestInfo
                    data={otherDistributions.pending}
                    total={otherDistributions.pendingTotal}
                    totalType={'Pending'}
                  />
                ),
              },
              {
                title: 'PAID REQUESTS',
                isEmpty: otherDistributions.paid.length === 0,
                details: (
                  <RequestInfo
                    data={otherDistributions.paid}
                    total={otherDistributions.paidTotal}
                    totalType={'Paid'}
                  />
                ),
              },
            ].filter((a) => !a.isEmpty)}
          />
        </div>
      </div>
    </div>
  )
}

export default Header
