import React from 'react'
import styles from './Header.css'

const SectionHeader = ({ title }) => {
  return (
    <div className={styles.sectionHeader}>
      <h5>{title}</h5>
    </div>
  )
}

export default SectionHeader
