import React from 'react'
import styles from './styles.css'
import formatters from '../../../../../lib/formatters'
import { StatusName } from '../Main/constant'
import { Link } from 'react-router-dom'

const RequestInfo = ({ data, total, totalType }) => {
  if (data.length == 0) {
    return
  }
  return (
    <div className={styles.requestInfoContainer}>
      <div className={styles.requestInfo}>
        {data?.map((item, index) => {
          return (
            <div key={`${index}-request-info-list`} className={styles.requstInfoList}>
              <div className={styles.requestInfoItem}>
                {item.xeroPayToName ? item.xeroPayToName : 'N/A'}
              </div>
              <div className={styles.requestInfoItem}>
                {item?.amount ? formatters.money(item.amount) : '--'}
              </div>
              {totalType === 'Paid' ? (
                <div className={styles.requestInfoItem} style={{ textTransform: 'uppercase' }}>
                  {formatters.date(item?.datePaid)}
                </div>
              ) : (
                <div className={styles.requestInfoItem} style={{ textTransform: 'uppercase' }}>
                  {StatusName[item.status] ?? 'In Process'}
                </div>
              )}
              <div className={styles.requestInfoItem}>
                <Link
                  to={`/distributions/${item?.id}`}
                  className={styles.requestInfoItem}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  ID {item?.id}
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div>
        <div className={styles.empty}></div>
        <div className={styles.infoTotal}>
          <span>Total {totalType}</span> <span>{formatters.money(total)}</span>
        </div>
      </div>
    </div>
  )
}

export default RequestInfo
