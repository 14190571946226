// @flow
import React from 'react'
import { connect } from 'react-redux'
import { httpGet } from 'lib/http'
import { Table, Row, Cell } from 'components/Table'
import Dropzone from 'react-dropzone'
import { Dialog } from 'components/Dialog'
import styles from './styles'

type Props = {
  children: [Object],
  content_type: String,
  dispatch: Function,
  files: [Object],
  key: String,
  link_type: String,
  name: String,
  onDeleteClick: Function,
  onDeleteLinkClicked: Function,
  onFileDrop: Function,
  onFileLinkClicked: Function,
  onUploadClick: Function,
  rows: [Row],
}

const viewableContentTypes = [
  'application/pdf',
  'text/plain',
  'image/png',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'audio/mpeg',
  'video/mpeg',
]

const TableComponent = ({ rows }: Props) => (
  <Table headers={[]} tableSize="infinite" tableType="read" noResults="">
    {rows}
  </Table>
)

const FilesTable = ({
  files = [],
  onDeleteLinkClicked = null,
  onFileLinkClicked = null,
}: Props) => {
  const deleteLink = (id) => (
    <div className={styles.remove}>
      <a title="Delete File" onClick={() => onDeleteLinkClicked(id)} />
    </div>
  )

  const downloadLink = ({ key, name }) => (
    <div className={styles.download}>
      <a
        title="Download File"
        onClick={() => onFileLinkClicked(key, 'download')}
      >
        {name}
      </a>
    </div>
  )

  const previewLink = ({ key, content_type }) => {
    if (viewableContentTypes.includes(content_type)) {
      return (
        <div className={styles.preview}>
          <a
            title="Preview File"
            onClick={(e) => {
              e.preventDefault()
              onFileLinkClicked(key, 'preview')
            }}
          />
        </div>
      )
    } else {
      return ' '
    }
  }

  const fileRow = (file, idx) => {
    return (
      <Row key={idx + 1} className={styles.tableRow}>
        <Cell value={downloadLink(file)} className={styles.tableCell} />
        <Cell value={previewLink(file)} className={styles.searchIcon} />
        <Cell value={deleteLink(file.id)} className={styles.deleteLink} />
      </Row>
    )
  }

  const fileRows = files.map((f, idx) => fileRow(f, idx))

  return <TableComponent rows={fileRows} />
}

class SimpleFileFolder extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      deleteFileId: null,
      uploadFile: null,
      uploadFileName: '',
    }
  }

  table = () => {
    if (!this.props.files || this.props.files.length === 0) {
      return <div className={styles.noFiles}></div>
    }

    return (
      <FilesTable
        files={this.props.files}
        onDeleteLinkClicked={this.deleteLinkClicked}
        onFileLinkClicked={this.fileLinkClicked}
      />
    )
  }

  dropHandler = () => {
    return (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0]
      this.setState({
        uploadFile: acceptedFile,
        uploadFileName: acceptedFile.name,
      })
    }
  }

  customizeUploadFileName = (event) => {
    this.setState({ uploadFileName: event.target.value })
  }

  fileLinkClicked = (key, link_type) => {
    const reqUrl = `/admin/files/${key}/${link_type}`

    httpGet(reqUrl).then(({ url }) => {
      link_type === 'preview'
        ? window.open(url, '_blank')
        : (window.location.href = url)
    })
  }

  uploadClicked = () => {
    if (!this.state.uploadFile) {
      return
    }

    this.props.onUploadClick(this.state.uploadFileName, this.state.uploadFile)

    this.setState({ uploadFileName: '', uploadFile: null })
  }

  deleteLinkClicked = (id) => {
    this.setState({ deleteFileId: id })
  }

  signalDelete = () => {
    this.props.onDeleteClick(this.state.deleteFileId)

    this.setState({ deleteFileId: null })
  }

  render() {
    const table = this.table()

    return (
      <div className={styles.fileFolders}>
        <div className={styles.uploadControls}>
          <Dropzone
            onDrop={this.dropHandler()}
            className={styles.dropzoneArea}
            activeClassName={styles.drag}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dropzoneArea} {...getRootProps()}>
                <div className={styles.upIcon} />
                <div className={styles.uploadText}>
                  Drop here or click to browse
                </div>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          {table}
          <div
            className={
              this.state.uploadFile ? styles.lowerUploadControls : styles.none
            }
          >
            <input
              id="fileNameInput"
              className={styles.fileNameInput}
              type="text"
              placeholder="Name"
              value={this.state.uploadFileName}
              onChange={this.customizeUploadFileName}
            />
            <button
              type="button"
              className={styles.fileUploadButton}
              onClick={this.uploadClicked}
            >
              Upload
            </button>
          </div>
        </div>
        {this.state.deleteFileId && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes, Delete"
            headerText="Are you sure you want to delete this file?"
            onCancel={() => this.setState({ deleteFileId: null })}
            onConfirm={() => this.signalDelete()}
          />
        )}
      </div>
    )
  }
}

export default connect()(SimpleFileFolder)
