import AccountSearch from 'components/AccountSearch'
import SimpleFileFolder from 'components/SimpleFileFolder'
import DateInput from '../../../../../../components/DateInput'
import SectionHeader from '../../Header/SectionHeader'
import formatters from 'lib/formatters'
import styles from '../styles.css'
import { DRF_TYPE, MONTHS } from '../constant'
import { Link } from 'react-router-dom'
import { IconRecurring } from '../../../../../../components/Icons/IconRecurring'

export default function WriteFormOne({
  onUploadClick,
  files,
  onDeleteClick,
  form,
  updateForm,
  formDisabled,
  handleAccountSelect,
  recurringFormDisabled,
  handleRecurringUpdate,
  currentDrfRecurring,
  nextStatus,
  lockedForEdit,
  drfType,
}) {
  const disableFields = formDisabled && ['admin_wrapped_up'].includes(nextStatus)

  const getEndDate = (type, month) => {
    if (form?.recurringDistribution?.period == 'monthly' && form.recurringDistribution?.dayRepeats != null) {
      return formatters.getDateOn(3, type, form.recurringDistribution?.dayRepeats)
    }
    return formatters.getDateOn(month, type)
  }

  const getMaxDate = () => {
    const date = formatters.getDateOn(12, 'forth')
    // Convert to a Date object
    const currentDate = new Date(date)
    // Set the date to the first day of the next month and subtract one day
    const lastDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    currentDate.setDate(lastDateOfMonth.getDate())

    return currentDate.toISOString().split('T')[0]
  }

  const getRecurringColor = (area) => {
    if (drfType == DRF_TYPE.SCHEDULED) {
      if (area === 'body') {
        return styles.recurringDistributionSection
      } else {
        return styles.recurringHeader
      }
    }
  }
  return (
    <div className={styles.sectionOne}>
      {/* recurring section  */}
      {drfType != DRF_TYPE.STANDARD && (drfType == DRF_TYPE.TEMPLATE || currentDrfRecurring) && (
        <section className={`${styles.cardMarginBot} ${getRecurringColor('body')}`}>
          {(formDisabled || recurringFormDisabled || drfType == DRF_TYPE.SCHEDULED) && <div className={styles.disabledMask}></div>}
          <SectionHeader title={'Recurring'} icon={<IconRecurring />} className={getRecurringColor()} />
          {/* if template don't show purple color */}
          <section className={`${styles.sectionNoMargin} ${getRecurringColor('body')}`}>
            {form.templateId && (
              <section style={{ marginBottom: '1.75rem' }}>
                <Link to={`/distributions/${form.templateId}`} className={styles.recurringViewLink} target="_blank" referrerPolicy="no-referrer">
                  View this Recurring DRF Template: ID {form.templateId}
                </Link>
              </section>
            )}
            <section className={styles.subSection}>
              <div className={styles.radioButtonGroup}>
                <label className={styles.label} htmlFor="period">
                  Recurring Cycle
                </label>
                <div className={`${styles.buttons} ${styles.checkboxVertical}`}>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="weekly"
                      checked={form?.recurringDistribution?.period === 'weekly'}
                      onChange={handleRecurringUpdate}
                      disabled={drfType == DRF_TYPE.SCHEDULED}
                    />
                    <label htmlFor="weekly">Weekly (Scheduled payments begin the following week on Tuesday)</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="monthly"
                      checked={form?.recurringDistribution?.period === 'monthly'}
                      onChange={handleRecurringUpdate}
                      disabled={drfType == DRF_TYPE.SCHEDULED}
                    />
                    <label htmlFor="monthly">Monthly</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="period"
                      value="quarterly"
                      checked={form?.recurringDistribution?.period === 'quarterly'}
                      onChange={handleRecurringUpdate}
                      disabled={drfType == DRF_TYPE.SCHEDULED}
                    />
                    <label htmlFor="quarterly">Quarterly</label>
                  </div>
                </div>
              </div>
            </section>
            {['quarterly', 'monthly'].includes(form?.recurringDistribution?.period) && (
              <section className={styles.subSection}>
                <div className={styles.formItemHalf}>
                  <label className={styles.label} htmlFor="dayRepeats">
                    Initiate Request on This Day of the Month
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="dayRepeats"
                      value={form?.recurringDistribution?.dayRepeats || ''}
                      onChange={handleRecurringUpdate}
                      disabled={drfType == DRF_TYPE.SCHEDULED}
                      required
                    >
                      <option value="">Choose Day</option>
                      {[...new Array(31)].map((item, index) => (
                        <option key={`${index}-day-repeats-options`} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            )}
            {['quarterly', 'monthly'].includes(form?.recurringDistribution?.period) && (
              <section className={styles.subSection}>
                <div className={styles.formItemMono}>
                  <label className={styles.label} htmlFor="startMonth">
                    Start Month
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="startMonth"
                      value={form?.recurringDistribution?.startMonth || ''}
                      onChange={handleRecurringUpdate}
                      disabled={drfType == DRF_TYPE.SCHEDULED}
                      required
                    >
                      <option value="">Choose Day</option>
                      {MONTHS.map((item, index) => (
                        <option key={`${index}-start-month-options`} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>
            )}
            <section className={styles.subSection}>
              <div className={styles.formItemMono}>
                <label className={styles.label} htmlFor="endDate">
                  End Date / Next Review Date
                </label>
                <DateInput
                  name="endDate"
                  value={form.recurringDistribution?.endDate}
                  onChange={handleRecurringUpdate}
                  disabled={formDisabled || drfType == DRF_TYPE.SCHEDULED}
                  min={getEndDate('forth', 3)}
                  // max={formatters.getDateOn(12, 'forth')}
                  max={getMaxDate()}
                  required
                />
              </div>
            </section>
          </section>
        </section>
      )}
      {drfType == DRF_TYPE.TEMPLATE ? (
        <div className={styles.cardMarginBot}>
          {formDisabled && <div className={styles.disabledMask}></div>}
          <SectionHeader title="Related DRF's" />

          <section className={styles.sectionNoMargin}>
            <div className={styles.formItemHalf}>
              <div className={styles.associatedDrfTitle}>Paid distribution that initiated this requested recurring</div>
              <div className={styles.associatedDrf}>
                <p>{formatters.date(form.recurringDistribution.requestedFromDate)}</p>
                <Link
                  to={`/distributions/${form.recurringDistribution.requestedFromId}`}
                  className={styles.requestInfoItem}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  ID {form.recurringDistribution.requestedFromId}
                </Link>
              </div>
            </div>
            {form.recurringDistribution?.scheduledDistributions?.length > 0 && (
              <div className={styles.formItemHalf}>
                <div className={styles.scheduledDrfTitle}>Scheduled Payments from this Recurring Template</div>
                {form.recurringDistribution?.scheduledDistributions.map((scheduled) => (
                  <div className={styles.associatedDrfDate}>
                    <p>{formatters.date(scheduled)}</p>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      ) : (
        <section className={styles.cardMarginBot}>
          {!lockedForEdit && formDisabled && !['admin_wrapped_up'].includes(nextStatus) && <div className={styles.disabledMask}></div>}
          <SectionHeader title="Receive Info" />
          <section className={styles.sectionNoMargin}>
            <section className={styles.subSection}>
              <div className={styles.fileUpload}>
                <label className={styles.label}>Request Documents</label>
                <div className={styles.fileUploadComponent}>
                  <SimpleFileFolder onUploadClick={onUploadClick} files={files} onDeleteClick={onDeleteClick} />
                </div>
              </div>
            </section>

            <section className={styles.subSection}>
              <div className={styles.formItemMono}>
                <label className={styles.label} htmlFor="dateReceived">
                  Date Received
                </label>
                <DateInput
                  name="dateReceived"
                  value={form.dateReceived}
                  onChange={updateForm}
                  disabled={formDisabled || disableFields || drfType == DRF_TYPE.SCHEDULED}
                  required
                />
              </div>
            </section>
            <section className={styles.subSection}>
              <div className={styles.formItemMono}>
                <label className={styles.label} htmlFor="account">
                  Account Number
                </label>
                <div className={styles.searchComponent}>
                  <AccountSearch
                    onAccountSelect={handleAccountSelect}
                    valueInsideInput={form.account && `${form.account.number} - ${form.account.beneficiary}`}
                    disabled={formDisabled || drfType == DRF_TYPE.SCHEDULED}
                  />
                </div>
              </div>
            </section>

            <section className={styles.subSection}>
              <div className={styles.formItemHalf}>
                <label className={styles.label} htmlFor="requestType">
                  Request Type
                </label>
                <div>
                  <select
                    className={styles.select}
                    name="requestType"
                    value={form.requestType || ''}
                    onChange={updateForm}
                    disabled={formDisabled || disableFields || drfType == DRF_TYPE.SCHEDULED}
                    required
                  >
                    <option value="">Choose Type</option>
                    <option value="automobile">Automobile</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="food">Food</option>
                    <option value="housing">Housing</option>
                    <option value="insurance">Insurance</option>
                    <option value="misc">Misc</option>
                    <option value="services">Services</option>
                    <option value="utilities">Utilities</option>
                    <option value="credit_card">Credit Card</option>
                    <option value="reimburse">Reimbursement</option>
                  </select>
                </div>
              </div>
            </section>
            <section className={styles.subSection}>
              <div className={styles.radioButtonGroup}>
                <label className={styles.label} htmlFor="expeditedPaymentNeeded">
                  Expedited Payment Needed
                </label>
                <div className={styles.buttons}>
                  <input
                    type="radio"
                    name="expeditedPaymentNeeded"
                    value="yes"
                    checked={form.expeditedPaymentNeeded === 'yes'}
                    onChange={updateForm}
                    disabled={formDisabled || disableFields || drfType == DRF_TYPE.SCHEDULED}
                  />
                  <label htmlFor="yes">Yes</label>
                  <input
                    type="radio"
                    name="expeditedPaymentNeeded"
                    value="no"
                    checked={form.expeditedPaymentNeeded === 'no'}
                    disabled={disableFields || drfType == DRF_TYPE.SCHEDULED}
                    onChange={updateForm}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
            </section>
            {form.expeditedPaymentNeeded === 'yes' && (
              <section className={styles.subSection}>
                <div className={styles.formItemMono}>
                  <label className={styles.label} htmlFor="expeditedDateNeeded">
                    Expedited Payment Date Needed
                  </label>
                  <DateInput
                    name="expeditedDateNeeded"
                    value={form.expeditedDateNeeded}
                    onChange={updateForm}
                    disabled={formDisabled || disableFields || drfType == DRF_TYPE.SCHEDULED}
                    required
                  />
                </div>
              </section>
            )}
          </section>
        </section>
      )}
    </div>
  )
}
