import SendIcon from '@mui/icons-material/Send'
import { LockUnlockIcon } from '../permissions'
import { map } from 'lodash'

export const summaryBoxNavList = () => [
  { title: 'DRF MAIN PROCESSING QUEUE', countKey: 'main', theme: 'cyan' },
  { title: 'PENDING', countKey: 'pending', theme: 'amber' },
  { title: 'DENIED', countKey: 'denied', theme: 'orange' },
  { title: 'RECURRING PAYMENTS', countKey: 'recurring', theme: 'purple' },
  { title: 'ALL PAID', countKey: 'paid', theme: 'lime' },
]

export const tabTitleList = (type) => {
  const main = [
    { title: 'Write', countKey: 'written', tableTitle: 'Ready to Write' },
    {
      title: 'Admin Review',
      countKey: 'admin_reviewed',
      tableTitle: 'Ready to Review',
    },
    {
      title: 'Signer Review',
      countKey: 'signer_reviewed',
      tableTitle: 'Ready to Approve',
    },
    {
      title: 'Admin Wrap-Up',
      countKey: 'admin_wrapped_up',
      tableTitle: 'Ready to Approve',
    },
    { title: 'Paid Today', countKey: 'paid_today', tableTitle: 'Paid Today' },
  ]

  const pending = [
    { title: 'Pending CLIENT', countKey: 'pending_client' },
    { title: 'Pending INTERNAL', countKey: 'pending_internal' },
    { title: 'Aged Pending', countKey: 'aged_pending' },
  ]

  const paid = [{ title: 'All Paid', countKey: 'paid', tableTitle: 'All Paid' }]

  const denied = [
    { title: 'Denied', countKey: 'denied' },
    { title: 'Denied Closed', countKey: 'denied_closed' },
  ]

  const recurring = [
    { title: 'Write', countKey: 'recurring_written' },
    { title: 'Review', countKey: 'recurring_reviewed' },
    { title: 'Approve', countKey: 'recurring_approved' },
    { title: 'Scheduled', countKey: 'scheduled' },
    { title: 'Expired', countKey: 'expired' },
  ]

  if (type === 'main') return main
  else if (type === 'pending') return pending
  else if (type === 'denied') return denied
  else if (type === 'paid') return paid
  else if (type === 'recurring') return recurring
  return []
}

export const headers = ({ currentStatus, sort, nextStatus, handleFilter, className }) => {
  const createHeader = () => {
    let base = [
      {
        value: (
          <div style={{ lineHeight: '12px' }}>
            Days In <br /> Queue
          </div>
        ),
        sort: sort,
        filter: 'insertedAt',
        handleFilter,
        className,
        id: 'insertedAt',
      },
      { value: <SendIcon sx={{ color: '#8E3132' }} />, className, id: 'expeditedDateNeeded' },
      {
        value: 'Beneficiary',
        sort: sort,
        filter: 'account.number',
        handleFilter,
        className,
        id: 'beneficiary',
      },
      {
        value: 'Pay To',
        sort: sort,
        filter: 'xeroPayTo.name',
        handleFilter,
        className,
        id: 'xeroPayTo',
      },
      {
        value: 'Amount',
        sort: sort,
        filter: 'amount',
        handleFilter,
        className,
        id: 'amount',
      },
      {
        value: 'Request Type',
        sort: sort,
        filter: 'requestType',
        handleFilter,
        className,
        id: 'requestType',
      },
      {
        value: 'Last Updated',
        sort: sort,
        filter: 'lastUpdatedDate',
        handleFilter,
        className,
        id: 'lastUpdatedDate',
      },
      {
        value: 'By',
        sort: sort,
        filter: 'lastUpdatedUser',
        handleFilter,
        className,
        id: 'lastUpdatedUser',
      },
      { value: 'ID', sort: sort, filter: 'id', handleFilter, className, id: 'id' },
    ]
    const payByColumn = {
      value: 'Pay By',
      sort: sort,
      filter: 'paymentType',
      handleFilter,
      className,
      id: 'paymentType',
    }
    const ownerColumn = {
      value: 'Owner',
      sort: sort,
      filter: 'owner.name',
      handleFilter,
      className,
      id: 'owner',
    }
    if (['main', 'paid'].includes(currentStatus)) {
      if (nextStatus === 'written') {
        return base.filter((i) => !['Pay To', 'Amount'].includes(i.value))
      } else if (nextStatus === 'admin_wrapped_up') {
        const newArr = map(base, function (a) {
          return a.id === 'requestType' ? ownerColumn : a
        })

        return newArr.concat(payByColumn)
      } else {
        if (currentStatus == 'paid') {
          base = base.map((cell) => {
            if (cell.id === 'lastUpdatedDate') {
              return {
                value: 'Paid On',
                sort: sort,
                filter: 'datePaid',
                handleFilter,
                className,
                id: 'datePaid',
              }
            }
            return cell
          })
        }
        base.splice(3, 0, ownerColumn)
        return base.concat(payByColumn)
      }
    } else if (['pending', 'denied'].includes(currentStatus)) {
      let header = [
        {
          value: (
            <div style={{ lineHeight: '12px' }}>
              Days <br /> Pending
            </div>
          ),
          sort: sort,
          filter: 'pendingDistribution.dateEnteredPending',
          handleFilter,
          className,
          id: 'dateEnteredPending',
        },
        {
          value: 'Beneficiary',
          sort: sort,
          filter: 'account.number',
          handleFilter,
          className,
          id: 'beneficiary',
        },
        {
          value: 'Reason',
          sort: sort,
          filter:
            currentStatus === 'pending'
              ? 'pendingDistribution.reason'
              : 'deniedDistribution.reason',
          handleFilter,
          className,
          id: 'reason',
        },
        ownerColumn,
        {
          value: 'Last Updated',
          sort: sort,
          filter: 'lastUpdatedDate',
          handleFilter,
          className,
          id: 'lastUpdatedDate',
        },
        {
          value: 'Pay To',
          sort: sort,
          filter: 'xeroPayTo.name',
          handleFilter,
          className,
          id: 'xeroPayTo',
        },
        {
          value: 'Amount',
          sort: sort,
          filter: 'amount',
          handleFilter,
          className,
          id: 'amount',
        },
        {
          value: (
            <div style={{ lineHeight: '12px' }}>
              Days In <br /> Queue
            </div>
          ),
          sort: sort,
          filter: 'insertedAt',
          handleFilter,
          className,
          id: 'insertedAt',
        },
        { value: 'ID', sort: sort, filter: 'id', handleFilter, className, id: 'id' },
      ]
      if (currentStatus === 'denied') {
        return header
          .map((h) => {
            if (h.id === 'lastUpdatedDate') {
              return { ...h, value: 'Denied On' }
            }
            return h
          })
          .slice(1)
      } else if (currentStatus === 'pending') {
        const datePending = {
          value: (
            <div style={{ lineHeight: '12px' }}>
              Date Entered
              <br /> Pending
            </div>
          ),
          sort: sort,
          filter: 'pendingDistribution.dateEnteredPending',
          handleFilter,
          className,
          id: 'dateEnteredPending',
        }
        header.splice(5, 0, datePending)
      }
      return header
    }
    // add headers for the recurring table
    else if (['recurring'].includes(currentStatus)) {
      base = base.concat({
        value: 'Pay By',
        sort: sort,
        filter: 'paymentType',
        handleFilter,
        className,
        id: 'paymentType',
      })
      if (nextStatus == 'scheduled') {
        let arr = [
          {
            value: 'Run Now',
            sort: sort,
            filter: 'paymentType',
            handleFilter,
            className,
            id: 'paymentType',
          },
          ...base.slice(2).concat({
            value: (
              <div style={{ lineHeight: '12px' }}>
                Next Payment <br /> Date
              </div>
            ),
            sort: sort,
            filter: 'nextPaymentDate',
            handleFilter,
            className,
            id: 'nextPaymentDate',
          }),
        ]
        return arr
      }
      base = base.filter((cell) => cell.id !== 'expeditedDateNeeded')
      return base
    }
  }

  const arr = createHeader()

  const lockUnlock = {
    value: <LockUnlockIcon lockedForEdit={true} />,
    sort: sort,
    filter: 'lockedForEdit',
    handleFilter,
    className,
  }
  // if (process.env.NODE_ENV === 'development') {
  arr.push(lockUnlock)
  // }
  return arr
}

export const getNewStatus = (currentStatus, index) => {
  let newStatus
  if (currentStatus === 'main') {
    switch (index) {
      case 0:
        newStatus = 'written'
        break
      case 1:
        newStatus = 'admin_reviewed'
        break
      case 2:
        newStatus = 'signer_reviewed'
        break
      case 3:
        newStatus = 'admin_wrapped_up'
        break
      case 4:
        newStatus = 'paid_today'
        break
      case 5:
        newStatus = 'paid'
        break
    }
  } else if (currentStatus === 'pending') {
    switch (index) {
      case 0:
        newStatus = 'pending_client'
        break
      case 1:
        newStatus = 'pending_internal'
        break
      case 2:
        newStatus = 'aged_pending'
        break
    }
  } else if (currentStatus === 'paid') {
    switch (index) {
      case 0:
        newStatus = 'paid'
        break
    }
  } else if (currentStatus === 'recurring') {
    switch (index) {
      case 0:
        newStatus = 'recurring_written'
        break
      case 1:
        newStatus = 'recurring_reviewed'
        break
      case 2:
        newStatus = 'recurring_approved'
        break
      case 3:
        newStatus = 'scheduled'
        break
      case 4:
        newStatus = 'expired'
        break
    }
  } else if (currentStatus === 'denied') {
    switch (index) {
      case 0:
        newStatus = 'denied'
        break
      case 1:
        newStatus = 'denied_closed'
        break
    }
  }
  return newStatus
}

export const StatusActionMapper = {
  written: 'Write',
  admin_reviewed: 'Admin Review',
  signer_reviewed: 'Signer Review',
  admin_wrapped_up: 'Admin Wrap-Up',
  paid_today: 'Paid',
  pending_client: 'Pending Client',
  pending_internal: 'Pending Internal',
  aged_pending: 'Aged Pending',
  pending: 'Pending',
  paid: 'Paid',
  denied: 'Denied',
  denied_closed: 'Denied Closed',
  recurring_written: 'Recurring Write',
  recurring_reviewed: 'Recurring Review',
  recurring_approved: 'Recurring Approve',
  scheduled: 'Scheduled',
  expired: 'Expired',
  recurring_expired: 'Recurring Expired',
}

export const getStatusAction = (action) => {
  if (!action) return '---'
  return StatusActionMapper[action] ?? '---'
}
