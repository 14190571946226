import CustomStepper from 'components/CustomStepper'
import { colorTheme } from '../colors/color'
import styles from './styles.css'
import SendIcon from '@mui/icons-material/Send'
import { IconRecurring } from '../../../../../components/Icons/IconRecurring'
import { LockUnlockIcon } from '../permissions'

export default function DrfHeader({
  stepper,
  color,
  expeditedDate,
  daysInPending,
  currentDrfRecurring,
  lockedForEdit,
}) {
  return (
    <div className={styles.infoStripContainer}>
      <div className={styles.drfStrip}>
        <div
          className={`${styles.infoStrip} ${colorTheme[color].bgDark} ${styles.mb0} ${styles.infoStripMargin}`}
        >
          <p className={styles.drfTitle}>
            {stepper?.subheader && (
              <span className={styles.drfSubheader}>{stepper?.subheader}: </span>
            )}
            {stepper.title}
          </p>
          {daysInPending && (
            <p className={styles.pendingRight}>
              <span className={styles.daysInPending}>DAYS IN PENDING</span> {daysInPending}
            </p>
          )}
        </div>
        <div className={`${styles.drfBody} ${colorTheme[color].bgLight}`}>
          <div className={styles.headerStepper}>
            {stepper?.stepper?.steps && (
              <CustomStepper
                steps={stepper?.stepper?.steps}
                activeStep={stepper?.stepper?.activeStep}
                width={300}
              />
            )}
          </div>
          {stepper?.id && (
            <div className={styles.templateId}>
              {stepper?.idTitle}: <span> {stepper?.id}</span>
            </div>
          )}
          <div className={styles.iconContainer}>
            <div className={styles.lockUnlockIcon}>
              <LockUnlockIcon lockedForEdit={lockedForEdit} />
            </div>
            {expeditedDate && <SendIcon sx={{ color: '#8E3132' }} fontSize="large" />}
            {currentDrfRecurring && <IconRecurring />}
          </div>
        </div>
      </div>
    </div>
  )
}
