// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pendingRequest__pendingRequestLayout___vMvgP {
  display: flex;
  gap: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.pendingRequest__pendingRequestContainer___mevMn {
  background-color: white;
  width: 100%;
}

.pendingRequest__pendingRequestContainer___mevMn p {
    font-weight: 600;
    font-size: 14px;
  }

.pendingRequest__sections___wzJtX {
  padding-top: 0 !important;
}

.pendingRequest__sections___wzJtX section {
    width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
  }

.pendingRequest__sections___wzJtX section:nth-child(1n) {
    float: left;
    margin-right: 30px;
    clear: none;
  }

.pendingRequest__sections___wzJtX section:last-child {
    margin-right: 0;
  }

.pendingRequest__sections___wzJtX section:nth-child(2n) {
    margin-right: 0;
    float: right;
  }

.pendingRequest__sections___wzJtX section:nth-child(2n + 1) {
    clear: both;
  }
.pendingRequest__radioClear___TCKpX {
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedDark"]};
}
.pendingRequest__radioClear___TCKpX:hover {
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Distributions/components/Main/pendingRequest.css"],"names":[],"mappings":"AAIA;EAEE,aAAa;EACb,MAAM;EACN,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EAEE,uBAAuB;EACvB,WAAW;AAKb;;AAJE;IACE,gBAAgB;IAChB,eAAe;EACjB;;AAGF;EAEE,yBAAyB;AAI3B;;AAHE;IACE,8CAAkB;EACpB;;AAFA;IACE,WAAkB;IAAlB,kBAAkB;IAAlB,WAAkB;EACpB;;AAFA;IACE,eAAkB;EACpB;;AAFA;IACE,eAAkB;IAAlB,YAAkB;EACpB;;AAFA;IACE,WAAkB;EACpB;AAEF;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,mDAAgB;AAClB;AACA;EACE,eAAe;EACf,kCAA0B;EAA1B,0BAA0B;AAC5B","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtGray, gtRedDark from colors;\n\n.pendingRequestLayout {\n  composes: content-container from helpers;\n  display: flex;\n  gap: 0;\n  padding: 0 !important;\n  margin: 0 !important;\n}\n\n.pendingRequestContainer {\n  composes: cardMarginBot from helpers;\n  background-color: white;\n  width: 100%;\n  p {\n    font-weight: 600;\n    font-size: 14px;\n  }\n}\n\n.sections {\n  composes: sectionNoMargin from helpers;\n  padding-top: 0 !important;\n  section {\n    lost-column: 1 / 2;\n  }\n}\n.radioClear {\n  background: none;\n  outline: none;\n  border: none;\n  font-size: 12px;\n  color: gtRedDark;\n}\n.radioClear:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtGray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]}`,
	"gtRedDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedDark"]}`,
	"pendingRequestLayout": `pendingRequest__pendingRequestLayout___vMvgP ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"pendingRequestContainer": `pendingRequest__pendingRequestContainer___mevMn ${___CSS_LOADER_ICSS_IMPORT_1___.locals["cardMarginBot"]}`,
	"sections": `pendingRequest__sections___wzJtX ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionNoMargin"]}`,
	"radioClear": `pendingRequest__radioClear___TCKpX`
};
export default ___CSS_LOADER_EXPORT___;
