import formatters from 'lib/formatters'
import { recurringStatus } from './constant'

export const main = [
  'written',
  'admin_reviewed',
  'signer_reviewed',
  'admin_wrapped_up',
  'paid_today',
]
export const pending = ['pending_client', 'pending_internal', 'aged_pending', 'pending']
const paid = ['paid']
export const denied = ['denied', 'denied_closed']
export const recurring = [
  'recurring_written',
  'recurring_reviewed',
  'recurring_approved',
  'scheduled',
  'expired',
  'recurring_expired',
]

export const getColor = (nextStatus) => {
  if (main.includes(nextStatus)) {
    return 'cyan'
  } else if (pending.includes(nextStatus)) {
    return 'amber'
  } else if (paid.includes(nextStatus)) {
    return 'lime'
  } else if (denied.includes(nextStatus)) {
    return 'orange'
  } else if (recurring.includes(nextStatus)) {
    return 'purple'
  }
  return 'cyan'
}

// date should be in MM/DD/YY
export const getStepper = ({ currentStatus, form, events }) => {
  // const writeDate = events.
  const mainStepper = [
    {
      label: 'Receive',
      date: formatters.date(form.dateReceived),
    },
    {
      label: 'Write',
      date: formatters.date(getStatusByDescription(events, 'changed status to written')),
    },
    {
      label: 'Review',
      date: formatters.date(getStatusByDescription(events, 'changed status to admin reviewed')),
    },
    {
      label: 'Approve & Pay',
      date: formatters.date(getStatusByDescription(events, 'Changed status to Paid')),
    },
  ]

  if (currentStatus === 'pending') {
    mainStepper.pop()
    mainStepper.push({ label: 'Pending' })
  }

  return mainStepper
}

export const setActiveStep = (currentStatus) => {
  switch (currentStatus) {
    case 'received':
      return 1
    case 'written':
      return 2
    case 'admin_reviewed':
    case 'pending':
      return 3
    case 'signer_reviewed':
      return 4
    case 'paid':
      return 5
    default:
      return null
  }
}

export const getHeaderBasedOnEvents = (events, currentStatus, nextStatus, form) => {
  let steps = events
    ?.filter((e) => e.description.includes('Changed status'))
    ?.map((e) => ({
      label: e.description.replace('Changed status to ', ''),
      date: formatters.date(e.createdAt),
    }))
    .reverse()
  let stepsLength = steps?.length - 1

  // handling denied status
  if (
    currentStatus === 'denied' ||
    (currentStatus !== 'denied_closed' && nextStatus === 'denied_closed')
  ) {
    steps.push({
      label: 'Denied Closed',
      date: null,
    })

    if (currentStatus === 'denied') {
      stepsLength += 1
    }
  } else if (currentStatus === 'denied_closed') {
    stepsLength += steps.length + 1
  }
  // handle recurring status
  if (currentStatus === 'recurring_expired') {
    steps.push({
      label: 'Recurring Expired',
      date: null,
    })
    stepsLength = steps.length + 1
  }

  //handle status for paid
  if (currentStatus === 'paid') {
    stepsLength = steps.length + 1
  }

  return {
    steps: [
      {
        label: 'Receive',
        date: formatters.date(form.dateReceived),
      },
      ...steps,
    ],
    activeStep: stepsLength,
  }
}

export const getHeader = ({ headerTitle, currentStatus, nextStatus, form, id, events }) => {
  let stepper = null
  if (recurringStatus.includes(currentStatus) && currentStatus != 'recurring_expired') {
    stepper = getRecurringHeader(nextStatus, form, events)
  }
  if ([...paid, ...pending, ...denied, 'recurring_expired'].includes(currentStatus)) {
    stepper = getHeaderBasedOnEvents(events, currentStatus, nextStatus, form)
  }
  return {
    title: headerTitle,
    // subheader: 'Disbursement Request',
    stepper: {
      steps:
        stepper != null ? stepper.steps : getStepper({ currentStatus, nextStatus, form, events }),
      activeStep: stepper != null ? stepper.activeStep : setActiveStep(currentStatus),
    },
    id: id,
    idTitle: stepper?.idTitle ?? 'ID',
  }
}

export const getRecurringHeader = (nextStatus, form, events) => {
  let activeStep = 1
  switch (nextStatus) {
    case 'recurring_written':
      activeStep = 1
      break
    case 'recurring_reviewed':
      activeStep = 2
      break
    case 'recurring_approved':
      activeStep = 3
      break
  }
  const stepper = {
    steps: [
      { label: 'Write', date: formatters.date(form.dateReceived) },
      {
        label: 'Review',
        date: formatters.date(getStatusByDescription(events, 'changed status to admin reviewed')),
      },
      {
        label: 'Approve Template',
        date: formatters.date(getStatusByDescription(events, 'approved')),
      },
    ],
    activeStep,
    idTitle: 'TEMPLATE ID',
  }

  return stepper
}

export function areAllRequiredActionsCompleted(completedActions, requiredActions) {
  return requiredActions.every((action) => completedActions.includes(action))
}

const getStatusByDescription = (events, key) => {
  for (let i = 0; i < events.length; i++) {
    const description = events[i].description.toLowerCase()
    if (description.includes(key.toLowerCase())) {
      return events[i].createdAt
    }
  }

  return null
}

export const isMainQueue = function (currentStatus) {
  return ['received', ...main].includes(currentStatus)
}
