// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__customStepper___ocNGK {
  padding: 0 !important;
}

.styles__customStepper___ocNGK .styles__item___DdD9W {
    font-size: 9px;
    color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
    font-weight: 500;
    opacity: 0.6;
    margin-top: -6px;
    text-align: center;
  }

.styles__customStepper___ocNGK .styles__isActiveStep___oOeQR {
    opacity: 1;
  }

.styles__customStepper___ocNGK ol {
    flex-wrap: wrap !important;
    max-width: 80%;
    gap: 20px 0;
  }

.styles__ActiveDot___rQL77 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
}
.styles__ActiveCurrentDot___Gs0qm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
  /* border: 1px solid grey; */
}
.styles__DefaultDot___UQgmV {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid grey;
}

.styles__indicator___CtMfW {
  position: relative;
}

.styles__indicator___CtMfW .styles__headerTop___rDK42 {
    position: absolute;
    top: -14px;
    left: -12px;
    font-size: 8px;
    color: grey;
    font-weight: 400;
  }
`, "",{"version":3,"sources":["webpack://./universal/components/CustomStepper/styles.css"],"names":[],"mappings":"AAIA;EAEE,qBAAqB;AAiBvB;;AAhBE;IACE,cAAc;IACd,mDAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;EACpB;;AACA;IACE,UAAU;EACZ;;AACA;IACE,0BAA0B;IAC1B,cAAc;IACd,WAAW;EACb;;AAGF;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,8DAAwB;AAC1B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,8DAAwB;EACxB,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AASpB;;AARE;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,cAAc;IACd,WAAW;IACX,gBAAgB;EAClB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtBlue, gtGrayMid, gtGrayDark from colors;\n\n.customStepper {\n  composes: content-container from helpers;\n  padding: 0 !important;\n  .item {\n    font-size: 9px;\n    color: gtBlue;\n    font-weight: 500;\n    opacity: 0.6;\n    margin-top: -6px;\n    text-align: center;\n  }\n  .isActiveStep {\n    opacity: 1;\n  }\n  ol {\n    flex-wrap: wrap !important;\n    max-width: 80%;\n    gap: 20px 0;\n  }\n}\n\n.ActiveDot {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background-color: white;\n  border: 2px solid gtBlue;\n}\n.ActiveCurrentDot {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background-color: gtBlue;\n  /* border: 1px solid grey; */\n}\n.DefaultDot {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background-color: white;\n  border: 1px solid grey;\n}\n\n.indicator {\n  position: relative;\n  .headerTop {\n    position: absolute;\n    top: -14px;\n    left: -12px;\n    font-size: 8px;\n    color: grey;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtGrayMid": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayMid"]}`,
	"gtGrayDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayDark"]}`,
	"customStepper": `styles__customStepper___ocNGK ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"item": `styles__item___DdD9W`,
	"isActiveStep": `styles__isActiveStep___oOeQR`,
	"ActiveDot": `styles__ActiveDot___rQL77`,
	"ActiveCurrentDot": `styles__ActiveCurrentDot___Gs0qm`,
	"DefaultDot": `styles__DefaultDot___UQgmV`,
	"indicator": `styles__indicator___CtMfW`,
	"headerTop": `styles__headerTop___rDK42`
};
export default ___CSS_LOADER_EXPORT___;
