import { useEffect, useState } from 'react'
import { colorTheme } from '../colors/color'
import { navigateWithoutRedirect } from './configuration'
import styles from './styles'

const TableFilter = ({ filterKey, setFilterKey, color }) => {
  const [selectedOptions, setSelectedOptions] = useState(['all'])

  const handleCheckboxChange = (option) => {
    setFilterKey(option.filterKey)
    setSelectedOptions([option.value])
    if (option.filterKey != null) {
      navigateWithoutRedirect('fkid', JSON.stringify(option))
    }
  }

  const filterList = [
    { label: 'ALL TRUSTS', value: 'all', filterKey: null },
    { label: 'GPT Old', value: 'gpt-old', filterKey: ['G'] },
    { label: 'GPT New', value: 'gpt-new', filterKey: ['F'] },
    { label: 'GPT Checking', value: 'gpt-checking', filterKey: ['C'] },
    { label: 'GTA', value: 'GTA', filterKey: ['N', 'D'] },
    { label: 'GSNT New', value: 'gsnt-new', filterKey: ['J'] },
    { label: 'GSNT Old', value: 'gsnt-old', filterKey: ['T'] },
  ]

  useEffect(() => {
    if (!filterKey) {
      setSelectedOptions(['all'])
      navigateWithoutRedirect('fkid', JSON.stringify(filterList[0]))
    } else {
      const filterApplied = filterList.find(
        (f) => JSON.stringify(f.filterKey) === JSON.stringify(filterKey),
      )
      if (filterApplied != null) {
        setSelectedOptions([filterApplied.value])
      }
    }
  }, [filterKey])

  return (
    <div className={`${styles.tableFilter} ${colorTheme[color].bgDark}`}>
      <div className={styles.tableSelectFilter}>
        <p>FILTER RESULTS BY TRUST</p>
      </div>
      <div className={styles.checkboxGroup}>
        {filterList.map((option) => (
          <div
            key={option.value}
            className={
              selectedOptions.includes(option.value)
                ? `${styles.checkboxActive} ${colorTheme[color].textDark}`
                : styles.checkboxActiveInactive
            }
          >
            <input
              type="checkbox"
              id={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => handleCheckboxChange(option)}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TableFilter
